window.console = (function (origConsole) {
    if (!window.console || !origConsole) {
        origConsole = {};
    }

    var isDebug = window.isDebug,
        isSaveLog = false,
        logArray = {
            logs: [],
            errors: [],
            warns: [],
            traces: [],
            infos: []
        };

    return {
        log: function () {
            this.addLog(arguments, 'logs');
            isDebug &&
                origConsole.log &&
                origConsole.log.apply(origConsole, arguments);
        },
        warn: function () {
            this.addLog(arguments, 'warns');
            isDebug &&
                origConsole.warn &&
                origConsole.warn.apply(origConsole, arguments);
        },
        error: function () {
            this.addLog(arguments, 'errors');
            isDebug &&
                origConsole.error &&
                origConsole.error.apply(origConsole, arguments);
        },
        info: function (v) {
            this.addLog(arguments, 'infos');
            isDebug &&
                origConsole.info &&
                origConsole.info.apply(origConsole, arguments);
        },
        trace: function () {
            this.addLog(arguments, 'traces');
            isDebug &&
                origConsole.trace &&
                origConsole.trace.apply(origConsole, arguments);
        },
        debug: function (bool) {
            isDebug = bool;
        },
        saveLog: function (bool) {
            isSaveLog = bool;
        },
        addLog: function (args, array) {
            if (!isSaveLog) {
                return;
            }
            logArray[array || 'logs'].push(args);
        },
        logArray: function () {
            return logArray;
        }
    };
})(window.console);
